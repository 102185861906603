<template>
  <div class="p-4 position-relative">
    <div
      class="position-absolute"
      style="top: 0 !important; right: 0 !important"
    >
      <vs-button   icon dark v-b-modal="`modal_chart${type}`">
        <i :class="`text-success fas fa-${icon ?? 'up-right-from-square'}`"></i>
      </vs-button>
    </div>
    <!-- text info -->
    <div class="d-flex flex-column">
      <h3 class="text-white text-truncate">
        {{ title ?? "General info" }}
      </h3>
      <span class="font-weight-bold text-white h4">{{ info ?? "6 h" }}</span>
    </div>
    <!-- apexchart -->
    <!-- departamentos -->
    <div v-if="type === 1" id="chartPersona"></div>

    <!-- competencias persona -->
    <div v-if="type === 2" id="chart"></div>

    <!-- modalChart -->
    <b-modal
      size="xl"
      :id="`modal_chart${type}`"
      hide-footer
      hide-header
      centered
      @shown="onModalShown"
      body-class="body-modal-custom"
    >
      <div style="min-height: 450px">
        <h3 class="text-white-50">{{ title }}</h3>
        <h4 class="font-weight-bold">Promedios</h4>
        <!-- apexchartModal -->
        <!-- by persona competencias -->
        <div v-if="type === 1" id="modalChartPersona"></div>
        <!-- by departamentos -->
        <div v-if="type === 2" id="modalChart"></div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import ApexCharts from "apexcharts";

const colors = [
  "#26A0FC",
  "#26E7A6",
  "#FEBC3B",
  "#FF6178",
  "#8B75D7",
  "#69808A",
  "#46B3A9",
  "#D830EB",
];

var options = {
  series: [
    {
      name: "Promedio",
      data: [],
    },
  ],
  chart: {
    height: 300,
    type: "bar",
    events: {
      click: function (chart, w, e) {
        console.log(chart, w, e);
      },
    },
  },
  colors: colors,
  plotOptions: {
    bar: {
      columnWidth: "45%",
      distributed: true,
    },
  },
  tooltip: {
    x: {
      formatter: function (val) {
        return val;
      },
    },
  },
  dataLabels: {
    enabled: true,
  },
  legend: {
    show: false,
  },
  xaxis: {
    categories: [
      // ["John", "Doe"],
      // ["Joe", "Smith"],
      // ["Jake", "Williams"],
      // "Amber",
      // ["Peter", "Brown"],
      // ["Mary", "Evans"],
      // ["David", "Wilson"],
      // ["Lily", "Roberts"],
    ],
    labels: {
      formatter: (value) => {
        return value.length > 15 ? value.substring(0, 15) + "..." : value;
      },

      style: {
        colors: colors,
        fontSize: "12px",
      },
    },
  },
  yaxis: {
    max: 100,
  },
  responsive: [
    {
      breakpoint: 700,
      options: {
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        legend: {
          position: "right",
          verticalAlign: "top",
          containerMargin: {
            left: 35,
            right: 60,
          },
        },
      },
    },
  ],
};

export default {
  props: {
    title: String,
    info: String,
    icon: String,
    to: String,
    type: Number,
  },
  components: {},
  data() {
    return {
      usuario: {},
      chartPersonaData: [
        {
          EmpresaId: null,
          Empresa: null,
          EvaluadoId: null,
          Evaluado: null,
          Competencia: null,
          PromedioValoracion: 0,
        },
      ],
      chartData: [
        {
          EmpresaId: null,
          nombre_empresa: null,
          DepartamentoId: null,
          departamento: null,
          promedioValoracion: 0,
        },
      ],
      chartPersona: null,
      chart: null,
      modalChartPersona: null,
      modalChart: null,
    };
  },
  async mounted() {
    try {
      this.$isLoading(true);
      this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));

      // console.log(this.usuario);

      this.chartPersonaData = await this.getListEvaluacionesByPersona(
        this.usuario.id
      );

      this.chartData = await this.getListEvaluacionesByEmpresa(
        this.usuario.empresa.id
      );
      // Configurar y renderizar el gráfico principal
      // by departamenos
      this.setupChart(
        "chart",
        this.chart,
        this.chartData.slice(0, 5),
        options,
        2
      );

      // by persona competencias
      this.setupChart(
        "chartPersona",
        this.chartPersona,
        this.chartPersonaData.slice(0, 5),
        options,
        1
      );

      this.$isLoading(false);
    } catch (error) {
      this.$isLoading(false);
      // console.log("error", error);
    }
  },
  methods: {
    onModalShown() {
      if (!this.modalChart || !this.modalChartPersona) {
        if (this.type === 2) {
          this.setupChart(
            "modalChart",
            this.modalChart,
            this.chartData,
            options,
            2,
            400
          );
        } else {
          this.setupChart(
            "modalChartPersona",
            this.modalChartPersona,
            this.chartPersonaData,
            options,
            1,
            400
          );
        }
      }
    },
    // Función para configurar y renderizar el gráfico
    setupChart(idChart, chart, data, options, type, chartHeight) {
      const height = chartHeight || options.chart.height;
      chart = new ApexCharts(document.querySelector(`#${idChart}`), {
        ...options,
        chart: { ...options.chart, height: height },
      });
      chart.render();
      chart.updateSeries([
        { data: data.map((item) => item.promedioValoracion) },
      ]);
      chart.updateOptions({
        xaxis: {
          categories: data.map((item) =>
            type === 2 ? item.departamento : item.competencia
          ),
        },
      });
    },
    async getListEvaluacionesByPersona(personaId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path:
            "EstadisticasEvaluacionesEmpresaV2/PromedioEvaluacionesPersona/" +
            personaId,
        });
        console.log("list PromedioEvaluacionesCompetenciasByPersona... ", res);
        if (res.success) {
          return res.data;
        }
      } catch (error) {
        console.log("err", error);
      }
    },
    async getListEvaluacionesByEmpresa(empresaId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path:
            "EstadisticasEvaluacionesEmpresaV2/PromedioEvaluacionesDepartamentos/" +
            empresaId,
        });
        console.log("list PromedioEvaluacionesDepartamentos... ", res);
        if (res.success) {
          return res.data;
        }
      } catch (error) {
        console.log("err", error);
      }
    },
  },
  filters: {},
};
</script>
<style scoped></style>
