<template>
  <cardProgress
    :title="title"
    :info="info"
    :icon="icon"
    :to="to"
    :total="total"
    :confirmed="confirmed"
  />
</template>
<script>
import cardProgress from "@/components/cards/cardProgress.vue";

export default {
  props: {
    title: String,
    icon: String,
    to: String,
    type: String,
  },
  components: { cardProgress },
  data() {
    return {
      persona: {},
      info: null,
      total: null,
      confirmed: null,
    };
  },
  async mounted() {
    try {
      this.$isLoading(true);
      this.persona = JSON.parse(localStorage.getItem("setPersonaAct"));
      await this.getEstadisticasPersona(this.type);
      this.$isLoading(false);
    } catch (error) {
      this.$isLoading(false);
      // console.log("error", error);
    }
  },
  methods: {
    // estadisticas persona
    async getEstadisticasPersona(name) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: `EstadisticasDashboardPersona/${name}/${this.persona.id}`,
        });
        console.log("EstadisticasPersona --------------->", res);
        if (res.success) {
          const { finalizadas, total, ultimaFechaRegistro } = res.data;
          // totales
          this.total = total;

          //confirmados
          this.confirmed = finalizadas;

          // fecha creacion mas reciente
          this.info = this.calcularDiferenciaDeTiempo(ultimaFechaRegistro);
        }
      } catch (error) {
        console.log("err", error);
      }
    },
    calcularDiferenciaDeTiempo(fecha) {
      const fechaActual = new Date();
      const fechaEntrada = new Date(fecha);

      const diferenciaEnMilisegundos = fechaActual - fechaEntrada;
      const segundos = Math.floor(diferenciaEnMilisegundos / 1000);
      const minutos = Math.floor(segundos / 60);
      const horas = Math.floor(minutos / 60);
      const dias = Math.floor(horas / 24);
      const semanas = Math.floor(dias / 7);
      const meses = Math.floor(dias / 30);
      const años = Math.floor(dias / 365);

      if (segundos < 60) {
        return "Ahora";
      } else if (minutos === 1) {
        return "1 min";
      } else if (minutos < 60) {
        return `${minutos} min`;
      } else if (horas === 1) {
        return "1 h";
      } else if (horas < 24) {
        return `${horas} h`;
      } else if (dias === 1) {
        return "1 d";
      } else if (dias < 7) {
        return `${dias} d`;
      } else if (semanas === 1) {
        return "1 sem";
      } else if (semanas < 4) {
        return `${semanas} sem`;
      } else if (meses === 1) {
        return "1 mes";
      } else if (meses < 12) {
        return `${meses} meses`;
      } else if (años === 1) {
        return "1 año";
      } else {
        return `${años} años`;
      }
    },
  },
  filters: {},
};
</script>
<style scoped></style>
