<template>
  <b-container fluid>
    <b-row class="justify-content-lg-center">
      <div class="col-xl-3">
        <card style="height: 80%">
          <div class="pt-3 pb-5">
            <div class="px-5 text-center" style="position: relative; align-items: center !important">
              <img :src="sucursal.urlLogo != null ? sucursal.urlLogo : empresa.urlLogo" class="img-fluid" style="width: 12vw" alt="" />
            </div>
          </div>
        </card>
      </div>
      <div class="col-smd-12 col-xl-9">
        <card style="height: 80%">
          <div class="row px-4">
            <div class="col-sm-12 col-md-6 text-center">
              <div style="padding: 1% 0">
                <div style="padding: 5% 0">
                  <h3 class=""><b>AUTORIZACION PARA DESEMPEÑAR FUNCIONES</b><br /></h3>
                  <span class="h4 text-light"><b>Talento Humano</b></span>
                </div>
              </div>
            </div>

            <div class="col-sm-12 col-md-6 pt-1">
              <div class="row text-white text-left">
                <ul>
                  <li class="my-2"><b>Código:</b> {{ item.codigo }}</li>
                  <li class="my-2"><b>Versión:</b> {{ item.version }}</li>
                  <li class="my-2"><b>Implementación:</b> {{ $moment.tz(item.implementacion, "America/Bogota").format("ll") }}</li>
                  <li class="my-2"><b>Clasificación de la información:</b> {{ item.clasificacion }}</li>
                  <li class="my-2"><b>Fecha de expedición:</b> {{ $moment.tz(item.fechaActualizacion, "America/Bogota").format("ll") }}</li>
                  <li class="my-2"><b>Tipo:</b> {{ item.tipo == 0 ? "Período de prueba" : item.tipo == 1 ? "Evaluación de competencias" : item.tipo == 2 ? "Promoción" : "" }}</li>
                </ul>
              </div>
            </div>
          </div>
        </card>
      </div>

      <card class="py-0 col-xl-8">
        <div>
          <b-list-group class="my-0">
            <b-list-group-item class="my-2">
              <b-row>
                <b-col sm>
                  <!-- {{dataCicloEvaluacion}}
                  {{autorizacion}} -->
                  <p class="text-justify">
                    La Dirección de Talento Humano autoriza para desempeñar funciones a
                    <span class="h4" style="font-weight: bold">{{ usuario.nombres + " " + usuario.apellidos }}</span> identificado con
                    <span class="h4" style="font-weight: bold"> {{ usuario.tipoDocumento.descripcion }} </span> número
                    <span class="h4" style="font-weight: bold">{{ usuario.numeroDocumento }} </span> , quien ha superado a satisfacción la ejecución de sus funciones y
                    responsabilidades del cargo <span class="h4" style="font-weight: bold"> {{ cargo.cargo.nombre }} </span> de la Entidad de Certificación Digital Gestión de
                    Seguridad Electrónica S.A.
                  </p>
                  <p class="text-justify">
                    Talento Humano autoriza para desempeñar funciones, a quien ha superado el período de prueba o evaluación de competencias, y así cumplir con las
                    responsabilidades del cargo, las cuales se encuentran estipuladas en el perfil y funciones de cargo.
                  </p>
                  <p class="text-justify">Vigencia: hasta la próxima evaluación de Competencias.</p>
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
          <div class="my-2">
            <b-button variant="primary" @click="aceptarFuncion">Confirmar y Aceptar</b-button>
          </div>
        </div>
      </card>
    </b-row>
  </b-container>
</template>
<script>
import { mapGetters } from "vuex";
// ! Agregar versiones para los cargos
export default {
  name: "modalaceptarFunciones",
  props: ["dataCicloEvaluacion", "autorizacion"],
  data() {
    return {
      item: {},
      empresa: {},
      perfilCargoPersonaAceptado: null,
      usuario: { tipoDocumento: { descripcion: "" } },
      cargo: { cargo: { nombre: "" } },
      sucursal: {},
    };
  },
  async mounted() {
    // this.autorizacion = this.autorizacion[0]
    this.usuario = this.$store.getters.userLoggedIn;
    this.empresa = this.usuario.empresa;
    let ubicacionPersonaData = await this.$store.dispatch("hl_get", {
      path: "PersonaUbicacion/GetByPersona/" + this.usuario.id,
    });
    this.sucursal = ubicacionPersonaData.sucursal;

    console.log("usuario---------->", this.usuario);
    this.item = await this.getEmpresaAutorizacion();
    this.item = this.item[0];
    let { id } = this.$store.getters.userLoggedIn;

    this.$store.getters
      .fetchGet({ path: `PersonaUbicacion/GetByPersona/${id}` })
      .then((response) => response.json())
      .then((cargo) => (this.cargo = cargo))
      .catch((error) => console.log(error));
  },
  methods: {
    async getEmpresaAutorizacion() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "EmpresaAutorizacionFuncion/GetEmpresaAutorizacion/" + this.usuario.empresa.id,
        });
        console.log("....******************************************.", res);
        return res;
      } catch (error) {
        console.log("err", error);
      }
    },
    async aceptarFuncion() {
      let { id: userId } = this.$store.getters.userLoggedIn;

      let data = {
        PersonaId: userId,
        AutorizacionId: this.autorizacion[0].id,
        CicloEvaluacionId: this.dataCicloEvaluacion.cicloEvaluacionId,
        Promedio: this.dataCicloEvaluacion.promedio,
        CargoId: null,
        FechaAceptado: this.$moment.tz(new Date(), "America/Bogota").format("YYYY-MM-DDTHH:mm"),
      };

      let res = await this.$store.dispatch("hl_post", {
        path: "Persona/AceptaPromedioCicloPersona",
        data: data,
      });
      console.log(res);
      if (res == true) {
        window.location.reload();
      }
    },
  },
  computed: {
    ...mapGetters(["toFormatDate"]),
    funcionesTipoG() {
      if (this.item.funciones) {
        return this.item.funciones.filter((f) => f.tipo == "G");
      } else {
        return [];
      }
    },
    funcionesTipoNotG() {
      if (this.item.funciones) {
        return this.item.funciones.filter((f) => f.tipo != "G");
      } else {
        return [];
      }
    },
  },
};
</script>
