<template>
  <div
    class="d-flex flex-column justify-content-center align-items-center"
    style="gap: 1rem"
  >
    <div class="progress-chart bg-danger " style="border-radius: 50%;"  >
      <svg width="180" height="180">
        <!-- Reducimos el tamaño del SVG -->
        <!-- Círculo exterior (borde vacío) con sombra en un color diferente -->
        <circle
       
          cx="90"
          cy="90"
          r="80"
          fill="none"
          :stroke="borderColor"
          :stroke-width="9"
          :stroke-opacity="0.5"
        />

        <!-- Círculo interior que se llenará con brillo y colores personalizados -->
        <circle
        
          :cx="90"
          :cy="90"
          r="80"
          fill="none"
          :stroke="circleColor"
          stroke-linecap="round"
          :stroke-width="12"
          :stroke-dasharray="circumference"
          :stroke-dashoffset="circumference * (1 - currentProgress)"
          :style="{
            transition: 'stroke-dashoffset 2s',
            filter: `drop-shadow(0px 0px 3px ${circleColor})`,
          }"
        />

        <!-- Porcentaje de progreso en el centro arriba del texto existente -->
        <text
          class="text-white"
          x="95"
          y="95"
          text-anchor="middle"
          alignment-baseline="middle"
          font-size="3.5rem"
          font-weight="bold"
          style="fill: var(--white) !important;"
          >
          {{ currentPercentage }}
          <tspan dy="0.5em" dx="-0.6em" font-size="1.5rem">%</tspan>
        </text>
      </svg>
    </div>
    <!-- info progress -->
    <div class="row d-flex justify-content-center">
      <!-- finalizadas -->
      <div class="col d-flex flex-column align-items-center">
        <vs-avatar icon success size="40" class="mr-1">
          <i class="fas fa-check-circle" style="font-size: 1.5rem"></i>
        </vs-avatar>
        <div class="d-flex flex-column align-items-center mt-2">
          <span style="font-size: 2rem">{{ progressConfirmed }}</span>
          <span class="text-white-50">{{ titleCheck ?? "Finalizadas" }}</span>
        </div>
      </div>
      <!-- divisor -->
      <div style="width: 1px; background-color: #102e5f"></div>

      <!-- totales -->
      <div class="col d-flex flex-column align-items-center">
        <vs-avatar icon primary size="40" class="mr-1">
          <i class="fas fa-trophy" style="font-size: 1.5rem"></i>
        </vs-avatar>
        <div class="d-flex flex-column align-items-center mt-2">
          <span style="font-size: 2rem">{{ textTotal }}</span>
          <span class="text-white-50">Totales</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    titleCheck: String,
    total: Number, // total
    confirmed: Number, // confirmado
  },
  data() {
    return {
      currentProgress: 0, // Inicialmente 0
    };
  },
  computed: {
    progressPercentage() {
      return Math.floor(this.confirmed) / Math.floor(this.total);
    },
    borderColor() {
      return "var(--muted-progress)"; // Color del borde exterior
    },
    circleColor() {
      // Cambia el color del círculo interior según diferentes rangos de porcentaje
      if (this.progressPercentage < 0.4) {
        return "#DF122E"; // Rojo para menos del 40%
      } else if (this.progressPercentage < 0.8) {
        return "rgb(255, 130, 0)"; // Amarillo para menos del 80%
      } else {
        return "rgb(23, 201, 100)"; // Verde para el resto
      }
    },
    progressConfirmed() {
      return `${this.confirmed}`;
    },
    textTotal() {
      return `${this.total}`;
    },
    currentPercentage() {
      return Math.min(Math.round(this.currentProgress * 100), 100); // Limitar a un máximo de 100%
    },
    circumference() {
      const radius = 80; // Radio del círculo
      return 2 * Math.PI * radius;
    },
  },
  watch: {
    total() {
      this.animateProgress();
    },
    confirmed() {
      this.animateProgress();
    },
  },
  methods: {
    animateProgress() {
      const targetProgress = this.progressPercentage;
      const animationDuration = 1000; // Duración de la animación en milisegundos (1 segundo)
      const fps = 60; // Cuadros por segundo

      const targetValue = Math.min(targetProgress, 1); // Limitar a un máximo de 1 (o 100%)
      const startValue = this.currentProgress; // Valor actual del progreso
      const increment =
        (targetValue - startValue) / (animationDuration / (1000 / fps));
      let current = startValue;

      const animate = () => {
        if (
          (increment > 0 && current < targetValue) ||
          (increment < 0 && current > targetValue)
        ) {
          current += increment;
          this.currentProgress = current;
          requestAnimationFrame(animate);
        }
      };

      animate();
    },
  },
  mounted() {
    // Inicia la animación cuando el componente se monta en el DOM
    this.animateProgress();
  },
};
</script>

<style scoped>
.progress-chart {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 180px;
}
</style>
